import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from '../servizi/servizi';

@Component({
  selector: 'servizi-dialog',
  templateUrl: 'servizi_dialog.html',
  styleUrls: ['servizi_dialog.css']
})
export class ServiziDialogComponent {
  //title = 'perardi-web';
  constructor(
    public dialogRef: MatDialogRef<ServiziDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  close(): void {
    this.dialogRef.close();
  }
}
