import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MDBBootstrapModule, MdbCardImageComponent } from 'angular-bootstrap-md';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuComponent } from './menubar/menubar';
import { FooterComponent } from './footer/footer';
import { MatFormFieldModule, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { ContactForm } from './forms/contactForm';
import {MatInputModule, MatButtonModule} from '@angular/material';
import {MatGridListModule} from '@angular/material/grid-list';
import { Homepage } from './homepage/home';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { ChiSiamoComponent } from './chisiamo/chi_siamo';
import { ServiziComponent } from './servizi/servizi';
import {MatCardModule} from '@angular/material/card';
import { ServiziCardComponent } from './servizi_card/servizi_card';
import {MatDialogModule} from '@angular/material/dialog';
import { ServiziDialogComponent } from './servizi_dialog/servizi_dialog';
import { GalleriaComponent } from './galleria/galleria';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { NgxGalleryModule } from 'ngx-gallery';
import { CustomHammerConfig } from './hammerConfig';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { GalleryDialogComponent } from './gallery_dialog/gallery_dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SlickCarouselModule } from 'ngx-slick-carousel';



@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    ContactForm,
    Homepage,
    ChiSiamoComponent,
    ServiziComponent,
    ServiziCardComponent,
    ServiziDialogComponent,
    GalleriaComponent,
    GalleryDialogComponent
    ],
  imports: [
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatCardModule,
    BrowserModule,
    MatGridListModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MDBBootstrapModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatCarouselModule.forRoot(),
    SlickCarouselModule,
    AnimateOnScrollModule.forRoot(),
    
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    NgxGalleryModule
  ],
  entryComponents: [
    ServiziDialogComponent,
    GalleryDialogComponent
  ],

  providers: [{
    provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig
}],
  bootstrap: [AppComponent]
})
export class AppModule { }

