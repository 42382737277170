import { Component } from '@angular/core';

@Component({
  selector: 'app-chisiamo',
  templateUrl: 'chi_siamo.html',
  styleUrls: ['chi_siamo.css']
})
export class ChiSiamoComponent {
  //title = 'perardi-web';
}
