import { Component } from '@angular/core';

@Component({
  selector: 'app-servizi-card',
  templateUrl: 'servizi_card.html',
  styleUrls: ['servizi_card.css']
})
export class ServiziCardComponent {
  //title = 'perardi-web';
}
