import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.html',
  styleUrls: ['footer.css']
})
export class FooterComponent {
  //title = 'perardi-web';

images=[
'geo.png',
'phone.png',
'piva.png',
'email.png'
];

  items=[
    'VIA FAVRIA, 64<br>RIVAROLO CANAVESE (TO)',
    'TEL. 0124 424450<br>FAX 0124 27326',
    'P.IVA<br>10235200010',
    'EMAIL<br>info@perardigiardini.it'
  ];

  targets=[
    '_blank',
    '',
    '',
    ''
  ]

  links=[
    'https://www.google.com/maps/place/PERARDI+GIARDINI/@45.331356,7.7075944,17z/data=!3m1!4b1!4m5!3m4!1s0x47886271d6eeb5cf:0xefbfe54327ede03b!8m2!3d45.331356!4d7.7097831',
    'tel:0124424450',
    '',
    'mailto:info@perardigiardini.it'
  ];
}
