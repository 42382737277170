import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: 'menubar.html',
  styleUrls: ['menubar.css']
})
export class MenuComponent implements OnInit{
i:number;
tog:boolean = false;
  ngOnInit(){
    this.i = 1;
  }
  check: boolean = false;
  //title = 'perardi-web';
  scrollTo(elem:string) {
    // alert(elem);
    let el = document.getElementById(elem);
    el.scrollIntoView();
  }

  scrollToMobile(elem:string) {
    this.closeContent();
    let input = document.getElementById('inputBtn');
    let el = document.getElementById(elem);
    el.scrollIntoView();
    this.check = false;
  }

  toggle(){
    if(!this.tog){
      
      // alert("apri");
      this.openContent();
    }else{
      // alert("chiudi");
      
      this.closeContent();
    }
  }

  openContent(){
    this.tog=true;
    let content = document.getElementById('content');
    let bar1 = document.getElementById('bar1');
    let bar2 = document.getElementById('bar2');
    let bar3 = document.getElementById('bar3');
    bar1.style.transform="rotate(45deg)";
    bar2.style.transform="translate(0, 2px)";
    bar3.style.display="none";
    bar2.style.transform="rotate(-45deg) translate(-5px,4px)";
    content.style.zIndex="4000";
    content.style.transform="translateX(calc(100vw + 108px))";

  }

  closeContent(){
    this.tog=false;
    let content = document.getElementById('content');
    let bar1 = document.getElementById('bar1');
    let bar2 = document.getElementById('bar2');
    let bar3 = document.getElementById('bar3');
    bar1.style.transform="rotate(0)";
    bar2.style.transform="rotate(0)";
    setTimeout(()=>{ bar3.style.display = "block" }, 280)

    content.style.zIndex="4000";
    
    content.style.transform="translateX(calc(-100vw))";
  }
}
