import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ServiziDialogComponent } from '../servizi_dialog/servizi_dialog';

@Component({
  selector: 'app-servizi',
  templateUrl: 'servizi.html',
  styleUrls: ['servizi.css']
})

export class ServiziComponent {
  //title = 'perardi-web';
  numbers = ['01', '02', '03', '04', '05', '06', '07', '08', '09'];
  titles = ['Giardini\n e Progettazione', 'Giardini\n Pensili', 
  'Impianti\n di irrigazione', 'Acqua \ne piscine', 'Impianti \nsportivi', 
  'Spazi \ninterni', 'Ingegneria \nnaturalistica', 'Arredi', 'Manutenzioni'];
  testi =[
    'Parte fondamentale è la progettazione che, tenendo conto degli obiettivi del cliente, delinea volumi e spazi abitativi esterni considerandoli una naturale estensione di quelli interni. '+
    'Il giardino è sempre accogliente, armonioso con il luogo circostante, variopinto o monocolore, lineare o sinuoso, rustico o innovativo, classico o personalizzato. ',

    'Realizzare un giardino pensile significa portare del verde sulle coperture di edifici o fabbricati in luogo dei tradizionali sistemi di impermeabilizzazione. '+
    'La filosofia del sistema è garantire perfette condizioni di vita della vegetazione ed allo stesso tempo consentire la collocazione di qualsiasi tipo di arredo e percorso (pedonale o carrabile). ',

    'L\'impianto di irrigazione è un elemento determinante nella realizzazione dei giardini "Perardi". '+
    'Il prato, le aiuole, le scarpate, gli alberi, hanno esigenze di acqua diverse: in funzione dell\'esposizione solare e della tipologia di terreno. '+
    'Per questo motivo Perardi Giardini progetta su misura gli impianti di irrigazione automatici offrendo soluzioni personalizzate.',

    'L\'acqua è elemento vitale e terapeutico per eccellenza, crea spazi di relax e benessere. '+
    'Piscine, ruscelli, laghetti, fontane e impianti di irrigazine permettono di valorizzare qualunque ambiente e realizzare angoli eleganti e decorativi. '+
    'Le moderne esigenze di vita contemplano sempre più la necessità di inserire elementi di valore terapeutico e ludico.',

    'Perardi Giardini opera in diversi campi dell\'impiantistica sportiva che vanno dalla realizzazione di quelli più specialistici come: percorsi natura, campi da tennis, campi da calcio in erba sintetica o tradizionali, campi da baseball e campi da golf. '+
    'Per effettuare questi interventi è necessario uno studio per la scelta dei materiali più idonei. L\'integrazione con impianti di irrigazione, drenaggio e illuminazione garantiscono la funzionalità delle opere realizzate. ',

    'Anche all\'interno si può costruire un giardino utilizzando una tecnica che permetta la rigogliosità in poco spessore. Si può anche arredare qualsiasi interno con vasi o piante disidratate. L\'effetto è immediato!',

    'Dal consolidamento dei versanti con palificate, palizzate, terre armate, alle opere idrauliche come traverse fluviali o difese spondali, Perardi realizza le opere combinandole con materiali naturali inerti (legno, pietrame, terreno), biodegradabili (biostuoie, geojuta) o artificiali (geogriglie, geotessili). ',

    'Arredare significa scegliere elementi o strutture da collocare nell’ambiente che soddisfino le esigenze personali e invoglino la vita in giardino. E’  importante che la proposta di gazebi, fioriere, panchine, giochi, fontane sia  sempre personalizzata',
    
    'L\'azienda offre il servizio di manutenzione ordinaria e programmata del verde, degli impianti e delle strutture. '+
    'La presenza di personale specializzato nelle potature, nella cura delle malattie e nella concimazione con prodotti specifici, garantisce risultati efficaci nel tempo e di alto valore estetico.',
  ];
  foto = [
  'pensile_su_soletta_m.jpg', 
  'giardino_pensile.jpg',
  'irrigazione_m.jpg', 
  'piscinab.JPG', 
  'golfa.JPG', 
  'spazi_interni_m.jpg',
  'palificata.JPG',
  'fontana_m.jpg',
  'manutenzioni.jpg'
];



  constructor(public dialog: MatDialog) {}

  openDialog(i): void {
    const dialogRef = this.dialog.open(ServiziDialogComponent, {
      width: '800px',
      data: {text: this.testi[i], title: this.titles[i]}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }


}


export interface DialogData {
  title: string;
  text: string;
}

