import {Component, OnInit, Inject} from '@angular/core';

import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ServiziDialogComponent } from '../servizi_dialog/servizi_dialog';
import { DialogData } from '../servizi/servizi';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';

@Component({
  selector: 'gallery-dialog',
  templateUrl: 'gallery_dialog.html',
  styleUrls: ['gallery_dialog.css']
})


export class GalleryDialogComponent {
 
    categorie = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9'
      ];
    
    constructor(
        public dialogRef: MatDialogRef<GalleryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
    
      close(): void {
        this.dialogRef.close();
      }

     gallery = new Array<NgxGalleryImage[]>();
      galleryOptions: NgxGalleryOptions[];
      giardiniPensili: NgxGalleryImage[];
      giardiniProgettazione: NgxGalleryImage[];
      irrigazione: NgxGalleryImage[];
      acqua: NgxGalleryImage[];
      impiantiSportivi: NgxGalleryImage[];
      spaziInterni: NgxGalleryImage[];
      ingNat: NgxGalleryImage[];
      arredi: NgxGalleryImage[];
      manu: NgxGalleryImage[];

    
      ngOnInit(): void {
    
          this.galleryOptions = [
              {
                  width: '100%',
                  height: '400px',
                  thumbnailsColumns: 0,
                  imageAnimation: NgxGalleryAnimation.Slide
              },
              // max-width 800
              {
                  breakpoint: 900,
                  width: '100%',
                  height: '400px',
                  imagePercent: 80,
                  thumbnailsPercent: 20,
                  thumbnailsMargin: 20,
                  thumbnailMargin: 20
              },
              // max-width 400
              {
                  breakpoint: 400,
                  preview: false
              }
          ];
          this.giardiniProgettazione = [
              {
                  small: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                  medium: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                  big: 'assets/PerardiWeb_files/pensile_su_soletta.jpg'
              },
              {
                small: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                medium: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                big: 'assets/PerardiWeb_files/pensile_su_soletta.jpg'
            },

            
          ];


          this.giardiniPensili = [
            {
                small: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                medium: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                big: 'assets/PerardiWeb_files/pensile_su_soletta.jpg'
            },
            {
              small: 'assets/PerardiWeb_files/fontana.jpg',
              medium: 'assets/PerardiWeb_files/fontana.jpg',
              big: 'assets/PerardiWeb_files/fontana.jpg'
          },

          
        ];


        this.irrigazione = [
            {
                small: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                medium: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                big: 'assets/PerardiWeb_files/pensile_su_soletta.jpg'
            },
            {
              small: 'assets/PerardiWeb_files/fontana.jpg',
              medium: 'assets/PerardiWeb_files/fontana.jpg',
              big: 'assets/PerardiWeb_files/fontana.jpg'
          },

          
        ];


        this.acqua = [
            {
                small: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                medium: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                big: 'assets/PerardiWeb_files/pensile_su_soletta.jpg'
            },
            {
              small: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
              medium: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
              big: 'assets/PerardiWeb_files/pensile_su_soletta.jpg'
          },

          
        ];


        this.spaziInterni = [
            {
                small: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                medium: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                big: 'assets/PerardiWeb_files/pensile_su_soletta.jpg'
            },
            {
              small: 'assets/PerardiWeb_files/fontana.jpg',
              medium: 'assets/PerardiWeb_files/fontana.jpg',
              big: 'assets/PerardiWeb_files/fontana.jpg'
          },

          
        ];

        this.impiantiSportivi = [
            {
                small: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                medium: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                big: 'assets/PerardiWeb_files/pensile_su_soletta.jpg'
            },
            {
              small: 'assets/PerardiWeb_files/fontana.jpg',
              medium: 'assets/PerardiWeb_files/fontana.jpg',
              big: 'assets/PerardiWeb_files/fontana.jpg'
          },

          
        ];





        this.ingNat = [
            {
                small: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                medium: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                big: 'assets/PerardiWeb_files/pensile_su_soletta.jpg'
            },
            {
              small: 'assets/PerardiWeb_files/fontana.jpg',
              medium: 'assets/PerardiWeb_files/fontana.jpg',
              big: 'assets/PerardiWeb_files/fontana.jpg'
          },

          
        ];


        this.arredi = [
            {
                small: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                medium: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                big: 'assets/PerardiWeb_files/pensile_su_soletta.jpg'
            },
            {
              small: 'assets/PerardiWeb_files/fontana.jpg',
              medium: 'assets/PerardiWeb_files/fontana.jpg',
              big: 'assets/PerardiWeb_files/fontana.jpg'
          },

          
        ];


        this.manu = [
            {
                small: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                medium: 'assets/PerardiWeb_files/pensile_su_soletta.jpg',
                big: 'assets/PerardiWeb_files/pensile_su_soletta.jpg'
            },
            {
              small: 'assets/PerardiWeb_files/fontana.jpg',
              medium: 'assets/PerardiWeb_files/fontana.jpg',
              big: 'assets/PerardiWeb_files/fontana.jpg'
          },

          
        ];


          this.gallery = [
              this.giardiniProgettazione,
              this.giardiniPensili,
              this.irrigazione,
              this.acqua,
              this.impiantiSportivi,
              this.spaziInterni,
              this.ingNat,
              this.arredi,
              this.manu

          ];
      }
    
}