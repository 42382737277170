import {Component, OnInit, Injectable} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { ServiziDialogComponent } from '../servizi_dialog/servizi_dialog';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import Swal from 'sweetalert2';


declare let Email : any;
/** @title Simple form field */
@Component({
  selector: 'form-contatti',
  templateUrl: 'contactForm.html',
  styleUrls: ['contactForm.css'],
})

@Injectable()
export class ContactForm implements OnInit{

  constructor(public http: HttpClient, public dialog: MatDialog) {}

  openConfirm(): void {
    const dialogRef = this.dialog.open(ServiziDialogComponent, {
      width: '300px',
      data: {text: "Risponderemo alla tua richieta appena possibile!", title: "Messaggio inviato!"}
    });

    dialogRef.afterClosed().subscribe(result => {
      window.location.reload();
      
      
    });
  }

  sendEmail(url, data) {
    console.log('SM');
    return this.http.post(url, data);
  }

  openAlert(): void {
    const dialogRef = this.dialog.open(ServiziDialogComponent, {
      width: '300px',
      data: {text: "Riempi tutti i campi prima di inviare!", title: "Attenzione!"}
    });

    dialogRef.afterClosed().subscribe(result => {
      //window.location.reload();
    });
  }
  ngOnInit() {
    console.log(this.http);
  }

  btnTxt= "Invia";

  contactForm = new FormGroup({
    nomeControl: new FormControl('', Validators.required),
    emailControl: new FormControl('', Validators.email),
    msgControl: new FormControl('', Validators.required)
  });
  submit(){
    let el = document.getElementById('ll');
      el.style.display="none";
    let user = {
      name: this.contactForm.controls.nomeControl.value,
      email: this.contactForm.controls.emailControl.value,
      mess: this.contactForm.controls.msgControl.value
    }
    console.log(user);
    if(user.name != "" &&user.email != "" && user.mess != ""){
      this.sendEmail("https://perardi-mailer.herokuapp.com/sendmail", user).subscribe(
      data => {
        let res:any = data; 
        console.log(
          'call done'
        );
      },
      err => {
        console.log(err);
      },() => {
        console.log('done');
        this.openConfirm();

      }
    );
    
    }else{
      this.openAlert();
    }
    
  }
  
params = {
  nome: this.contactForm.controls.nomeControl.value,
  email: this.contactForm.controls.emailControl.value,
  messaggio: this.contactForm.controls.msgControl.value,
}


  public mandaMail(e: Event) {
   
    console.log(this.contactForm.value.msgControl);
    console.log(this.contactForm);
    e.preventDefault();
    emailjs.send('gmail', 'test', {"nome": this.contactForm.value.nomeControl, "email": this.contactForm.value.emailControl, "messaggio":this.contactForm.value.msgControl}, 'user_pENPEndL26Eq3jUvQKxKu')
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
        Swal.fire({
          title: 'Messaggio Inviato!',
          text: 'Ti risponderemo al più presto.',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          title: 'OPS!',
          text: 'Qualcosa è andato storto! Riprova',
          icon: 'warning',
          confirmButtonText: 'RIPROVA'
        });
      });
  }

      
    
  
}