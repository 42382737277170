import {Component, OnInit} from '@angular/core';

import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { MatDialog } from '@angular/material';
import { ServiziDialogComponent } from '../servizi_dialog/servizi_dialog';
import { GalleryDialogComponent } from '../gallery_dialog/gallery_dialog';
/** @title Simple form field */
@Component({
  selector: 'galleria',
  templateUrl: 'galleria.html',
  styleUrls: ['galleria.css'],
})

export class GalleriaComponent implements OnInit {









  

  
  categorie = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9'
  ];

  foto = [
    'pensile_su_soletta.jpg', 
    'giardino_pensile.jpg',
    'irrigazione.jpg', 
    'piscine.jpg', 
    'impianti_sportivi.jpg', 
    'spazi_interni.jpg',
    'ing.JPG',
    'fontana.jpg',
    'manutenzione.jpg'
  ];


  titFoto = [
    'Pensile su soletta',
    'Giardino pensile',
    'Irrigazione',
    'Piscine',
    'Impianti Sportivi',
    'Spazi Interni',
    'Ingegneria Naturalistica',
    'Arredi',
    'Manutenzione'
  ];

  constructor(public dialog: MatDialog) {}

  openImage(i): void {
    const dialogRef = this.dialog.open(GalleryDialogComponent, {
      width: '800px',
      data: {images: i, title: this.titFoto[i], }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  ngOnInit(): void {
    // this.mobileSLide();
      this.galleryOptions = [
          {
              width: '900px',
              height: '600px',
              thumbnailsColumns: 0,
              imageAnimation: NgxGalleryAnimation.Slide
          },
          // max-width 800
          {
              breakpoint: 900,
              width: '100%',
              height: '600px',
              imagePercent: 80,
              thumbnailsPercent: 20,
              thumbnailsMargin: 20,
              thumbnailMargin: 20
          },
          // max-width 400
          {
              breakpoint: 400,
              preview: false
          }
      ];

      this.galleryImages = [
          {
              big: 'assets/PerardiWeb_files/giardino_pensile.jpg'
          },
         
      ];
  }

  mobileSLide(){
  
   let images : HTMLCollectionOf<Element>;
   images = document.getElementsByClassName('gim')
   let x = images.length;
  //  alert(x);
  for(let i = 0; i < x; i++){
    images[i-1].classList.remove('showImg');
    images[i-1].classList.add('hideImg');
    images[i].classList.remove('hideImg');
    images[i].classList.add('showImg');

  }
  }
  
  galleria(){
    const dialogRef = this.dialog.open(GalleryDialogComponent, {
      width: '800px',
   //   data: {images: i, title: this.titFoto[i], }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  
  slides = [
    {img: "../../assets/0732.jpg"},
    {img: "../../assets/0575.jpg"},
    {img: "../../assets/0559.jpg"},
    {img: "../../assets/0383.jpg"},
    {img: "../../assets/0328.jpg"},
    {img: "../../assets/0215.jpg"},
    {img: "../../assets/0190.jpg"},
    {img: "../../assets/0119.jpg"},
    {img: "../../assets/0061.jpg"},
    {img: "../../assets/0019.jpg"},
  ];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, autoplay:true};
  
  addSlide() {
    this.slides.push({img: "http://placehold.it/350x150/777777"})
  }
  
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }
  
  breakpoint(e) {
    console.log('breakpoint');
  }
  
  afterChange(e) {
    console.log('afterChange');
  }
  
  beforeChange(e) {
    console.log('beforeChange');
  }
}