import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    // let portHeight = document.getElementById('artCont').offsetTop;
    
    let goUp = document.getElementById('goUp');
    let scroll = window.pageYOffset;
   // console.log(portHeight+" "+scroll)
    if(scroll >= window.innerHeight/2){
    //  console.log("dentro if "+" menu height: "+menu.offsetHeight);
      console.log("show")
      goUp.style.position="fixed";
      goUp.style.bottom="16px";
      goUp.style.right="16px";
      goUp.style.zIndex="100";
      goUp.style.display="block";

    }else{
      goUp.style.display="none";
    }
  }

  goUp(){
    let menu = document.getElementById('headerCont');
    menu.scrollIntoView();
  }

  // loading: boolean = true;

 


}
